
          $PRIMARY-COLOR: #32AE89;
          $AUTH-BG-GRADIENT-0: #59D1AE;
          $AUTH-BG-GRADIENT-100: #2CA581;
        

.red-text {
  color: red;
}
.normal-text {
  color: rgb(178, 178, 178);
}
